@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* 기본 스타일 */
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* 네온 효과 애니메이션 */
@keyframes neonGlow {
  0% {
    text-shadow: 0 0 10px rgba(6, 182, 212, 0.5),
                 0 0 20px rgba(6, 182, 212, 0.3),
                 0 0 30px rgba(6, 182, 212, 0.1);
  }
  100% {
    text-shadow: 0 0 20px rgba(6, 182, 212, 0.8),
                 0 0 30px rgba(6, 182, 212, 0.5),
                 0 0 40px rgba(6, 182, 212, 0.3);
  }
}

.neon-text {
  animation: neonGlow 2s ease-in-out infinite alternate;
}

/* 스크롤 나타나기 효과 */
.fade-in-section {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

.fade-in-section.is-visible {
  opacity: 1;
  transform: translateY(0);
}

/* 프로젝트 이미지 스타일링 */
.project-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.project-image-container {
  overflow: hidden;
  position: relative;
}

.project-image-container:hover .project-image {
  transform: scale(1.05);
}

/* 프로젝트 카드 호버 효과 */
.project-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project-card:hover {
  transform: translateY(-5px);
}

/* 사이버펑크 스타일 버튼 */
.cyber-button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}

.cyber-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(6, 182, 212, 0.2),
    transparent
  );
  transition: all 0.6s ease;
}

.cyber-button:hover::before {
  left: 100%;
}

/* 배경 그리드 패턴 */
.cyber-grid {
  background-size: 50px 50px;
  background-image: 
    linear-gradient(to right, rgba(6, 182, 212, 0.1) 1px, transparent 1px),
    linear-gradient(to bottom, rgba(6, 182, 212, 0.1) 1px, transparent 1px);
}

/* 스크롤바 커스텀 */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #1f2937;
}

::-webkit-scrollbar-thumb {
  background: #374151;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #4b5563;
}

/* 폼 요소 스타일링 */
.form-input {
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-input:focus {
  box-shadow: 0 0 0 2px rgba(6, 182, 212, 0.2);
}

/* 모바일 메뉴 애니메이션 */
.mobile-menu {
  transition: transform 0.3s ease-in-out;
}

.mobile-menu.open {
  transform: translateX(0);
}

/* 스킬 카드 호버 효과 */
.skill-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.skill-card:hover {
  transform: scale(1.02);
}